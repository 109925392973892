import React, { useEffect } from 'react';
import { observer } from 'mobx-react';

import { adobeLaunchGlobalUrlSDK } from '~/global/global.constants';
import { noop } from '~/util/noop';
import { PersonalizationDecisionModel } from '~/personalization/Models/PersonalizationDecision.model';
import { personalzationModalSettings } from '~/personalization/Components/PersonalizationModal.constants';
import { PERSONALIZATION_SCOPE } from '~/personalization/Personalization.types';
import { useGlobalContext } from '~/global/Contexts/Global.context';

export const PersonalizationModal = observer(() => {
	const {
		accountNudgeModel,
		featureTogglesModel: {
			isOn = () => noop,
		} = {},
		magicModal = {},
		personalizationStore = {},
		personalizationStore: {
			decisionsModel = undefined,
			sendDisplayEvent = noop,
			sendInteractEvent = noop,
		} = {},
	} = useGlobalContext();

	if (!isOn('ADOBE_TARGET_WEBSDK') || !adobeLaunchGlobalUrlSDK) {
		return null;
	}

	const openPersoanlizationModal = (decision: PersonalizationDecisionModel) => {
		if (!decision) {
			return;
		}

		const {
			componentKey,
			isMockDecision = false,
		} = decision;

		// We can trigger a mock modal decision from the dev beam Extra Options modal - so we need to close that modal first
		if (isMockDecision) {
			magicModal.closeModal();
		}

		const modalComponent = personalzationModalSettings[componentKey as keyof typeof personalzationModalSettings];

		if (!modalComponent) {
			console.warn(`No modalTypeSettings found for modal ${componentKey}`);
			return;
		}

		const isMediaQueryMd = window.matchMedia('(min-width: 40em)').matches;

		const modalProps = {
			isMediaQueryMd,
			// do not sent interaction event to Target if this is a mock decision
			...(!isMockDecision && {
				clickHandler: () => {
					sendInteractEvent(decision);
				},
			}),
		};

		const modalSettingsWithProps = modalComponent ? modalComponent({
			isMediaQueryMd,
			modalProps,
		}) : {};

		setTimeout(() => {
			if (!magicModal.model.isOpen && !accountNudgeModel.showAccountNudge) {
				magicModal.openModal({
					onCloseModal: () => {
						magicModal.alterModal({
							containerClass: '',
							modalClassName: '',
						});
					},
					// do not sent display event to Target if this is a mock decision
					...(!isMockDecision && {
						onOpenModal: () => {
							sendDisplayEvent(decision);
						},
					}),
					...modalSettingsWithProps,
				});
			}
		}, 3000);
	};

	useEffect(() => {
		if (decisionsModel) {
			const decisionByScope = personalizationStore.getPersonalizationDecision(PERSONALIZATION_SCOPE.MODAL);

			if (decisionByScope) {
				const modalPersonalizationDecision = JSON.parse(JSON.stringify(decisionByScope));
				const modalPersonalizationDecisionModel = new PersonalizationDecisionModel({ decision: modalPersonalizationDecision });

				openPersoanlizationModal(modalPersonalizationDecisionModel);
			}
		}
	}, [decisionsModel]);

	return <></>;
});
