import { s7ImagePath } from '~/global/global.constants';
import { CoreCategoryLinkItem, CoreCategoryLinkList } from '~/components/core-categories/CoreCategories.type';

export enum CoreCategoryRoute {
	Bedroom = '/catalog/bedroom',
	Dining = '/catalog/dining-and-kitchen',
	Office = '/catalog/office',
	Storage = '/catalog/storage-and-entryway',
}

export const coreCategoryLinks: Record<CoreCategoryRoute, CoreCategoryLinkItem> = {
	[CoreCategoryRoute.Bedroom]: {
		caption: 'Bedroom',
		href: CoreCategoryRoute.Bedroom,
		imageHeight: 301,
		imageSrc: `${s7ImagePath}/HCC_bedroom_02_0624`,
		imageWidth: 542,
	},
	[CoreCategoryRoute.Dining]: {
		caption: 'Dining & Kitchen',
		href: CoreCategoryRoute.Dining,
		imageHeight: 301,
		imageSrc: `${s7ImagePath}/HCC_dining_02`,
		imageWidth: 542,
	},
	[CoreCategoryRoute.Office]: {
		caption: 'Office',
		href: CoreCategoryRoute.Office,
		imageHeight: 301,
		imageSrc: `${s7ImagePath}/HCC_office_01`,
		imageWidth: 542,
	},
	[CoreCategoryRoute.Storage]: {
		caption: 'Storage',
		href: CoreCategoryRoute.Storage,
		imageHeight: 301,
		imageSrc: `${s7ImagePath}/HCC_storage_03_0624`,
		imageWidth: 542,
	},
};

export const bedroomDiningStorageLinks: CoreCategoryLinkList = [
	coreCategoryLinks[CoreCategoryRoute.Bedroom],
	coreCategoryLinks[CoreCategoryRoute.Dining],
	coreCategoryLinks[CoreCategoryRoute.Storage],
];

export const officeBedroomStorageLinks: CoreCategoryLinkList = [
	coreCategoryLinks[CoreCategoryRoute.Office],
	coreCategoryLinks[CoreCategoryRoute.Bedroom],
	coreCategoryLinks[CoreCategoryRoute.Storage],
];
