import { customerFavoritesData } from '~/components/best-sellers/BestSellers.constants';
import { modernStyleGalleryData } from '~/components/customer-photos/CustomerPhotos.constants';
import { recentlyViewedData } from '~/components/product-recommendations/ProductRecommendations.constants';
import { officeBedroomStorageLinks } from '~/components/core-categories/CoreCategories.constants';
import { CONFIDENCEMSG_ID } from '~/homepage/Components/ConfidenceMsgs/ConfidenceMsg.type';
import { FEATCATS_ID } from '~/components/featured-categories/Components/HomepageFeatCats.type';
import { HERO_ID } from '~/components/hero/Components/HomepageHeroes.type';
import { IHomepageSettings } from '~/homepage/HomepageSettings.type';
import { seasonalFavesDiningList } from '~/components/tabbed-shopping/TabbedShopping.constants';
import { PROMOTIONALFLEX_ID } from '~/homepage/Components/PromotionalFlex/PromotionalFlex.type';

export const HomepageSettings: IHomepageSettings = {
	hero: HERO_ID.DINING,
	tabbedShopping: { tabList: seasonalFavesDiningList, headline: 'What We Love Right Now' },
	featCats: FEATCATS_ID.DINING_LIVING,
	confidenceMsg: CONFIDENCEMSG_ID.EsopMessage,
	coreCats: officeBedroomStorageLinks,
	bestSellers: customerFavoritesData,
	productRecommendations: recentlyViewedData,
	customerPhotos: modernStyleGalleryData,
	confidenceMsg2: CONFIDENCEMSG_ID.UrbanWoodConfidenceMessage,
	newCustomerBanner: CONFIDENCEMSG_ID.CraftspeopleMessage,
	promotionalFlex2: PROMOTIONALFLEX_ID.ParamusStorePromoFlex,
};
