import {
	model, Model, idProp, prop,
} from 'mobx-keystone';

import { modelNamespace } from '~/util/modelNamespace';
import { IPersonalizationComponentKey, IPropositions, IPropositionItem } from '~/personalization/Personalization.types';

@model(`${modelNamespace.PERSONALIZATION}/PersonalizationDecisionModel`)
export class PersonalizationDecisionModel extends Model({
	id: idProp,
	decision: prop<IPropositions | undefined>().withSetter(),
}) {
	get componentKey(): IPersonalizationComponentKey | undefined {
		const {
			data: {
				content: {
					component = undefined,
				} = {},
			} = {},
		} = this.decisionItem || {};
		return component;
	}

	get correlationId() {
		return this.decision?.scopeDetails.correlationID || undefined;
	}

	get decisionId() {
		return this.decision?.id || undefined;
	}

	get decisionItem(): IPropositionItem | undefined {
		if (!this.decision) {
			return undefined;
		}

		const { items } = this.decision;
		const [item] = items;

		return item;
	}

	get isMockDecision() {
		return this.decision?.mockDecision || false;
	}

	get scope() {
		return this.decision?.scope || undefined;
	}

	get scopeDetails() {
		return this.decision?.scopeDetails || undefined;
	}
}
