import { CLEARANCE } from '~/components/hero/Components/CLEARANCE';
import { DINING } from '~/components/hero/Components/DINING';
import { LIVING } from '~/components/hero/Components/LIVING';
import { NEW_ARRIVALS } from '~/components/hero/Components/NEW_ARRIVALS';

export const Heroes = {
	'CLEARANCE': CLEARANCE,
	'DINING': DINING,
	'LIVING': LIVING,
	'NEW_ARRIVALS': NEW_ARRIVALS,
};
