import { PROMOTIONALFLEX_ID } from '~/homepage/Components/PromotionalFlex/PromotionalFlex.type';
import { ClearancePromo } from '~/components/promotional-flex/Components/ClearancePromo';
import { MATaxFreePromo } from '~/components/promotional-flex/Components/MATaxFreePromo';
import { ParamusStorePromo } from '~/components/promotional-flex/Components/ParamusStorePromo';

export const PromotionalFlexs: Record<PROMOTIONALFLEX_ID, any> = {
	'ParamusStorePromoFlex': ParamusStorePromo,
	'ClearancePromoFlex': ClearancePromo,
	'MATaxFreePromoFlex': MATaxFreePromo,
};

export function isPromotionalFlexID(key: any): key is PROMOTIONALFLEX_ID {
	return key in PromotionalFlexs;
}
