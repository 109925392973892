import { ReadonlyURLSearchParams } from 'next/navigation';

import { PROMOTIONALFLEX_ID } from '~/homepage/Components/PromotionalFlex/PromotionalFlex.type';
import { BestSellersData } from '~/components/best-sellers/BestSellers.types';
import { CustomerPhotosData } from '~/components/customer-photos/CustomerPhotos.types';
import { ProductRecommendationsData } from '~/components/product-recommendations/ProductRecommendations.types';
import { CoreCategoryLinkList } from '~/components/core-categories/CoreCategories.type';
import { CONFIDENCEMSG_ID } from '~/homepage/Components/ConfidenceMsgs/ConfidenceMsg.type';
import { FEATCATS_ID } from '~/components/featured-categories/Components/HomepageFeatCats.type';
import { HERO_ID } from '~/components/hero/Components/HomepageHeroes.type';
import { IHomepageSettings } from '~/homepage/HomepageSettings.type';
import { TabbedShoppingTabList } from '~/components/tabbed-shopping/TabbedShopping.type';

export class HomepageModel {
	hero: HERO_ID;

	tabbedShopping: {headline: string, tabList: TabbedShoppingTabList};

	featCats: FEATCATS_ID;

	confidenceMsg: CONFIDENCEMSG_ID;

	confidenceMsg2: CONFIDENCEMSG_ID;

	newCustomerBanner: CONFIDENCEMSG_ID;

	coreCats: CoreCategoryLinkList;

	bestSellers: BestSellersData;

	customerPhotos: CustomerPhotosData;

	productRecommendations: ProductRecommendationsData;

	promotionalFlex1: PROMOTIONALFLEX_ID | null;

	promotionalFlex2: PROMOTIONALFLEX_ID | null;

	query: ReadonlyURLSearchParams | null;

	constructor(settings: IHomepageSettings, query: ReadonlyURLSearchParams | null) {
		this.hero = settings.hero;
		this.tabbedShopping = settings.tabbedShopping;
		this.featCats = settings.featCats;
		this.confidenceMsg = settings.confidenceMsg;
		this.coreCats = settings.coreCats;
		this.bestSellers = settings.bestSellers;
		this.customerPhotos = settings.customerPhotos;
		this.confidenceMsg2 = settings.confidenceMsg2;
		this.newCustomerBanner = settings.newCustomerBanner;
		this.productRecommendations = settings.productRecommendations;
		this.promotionalFlex1 = settings.promotionalFlex1 || null;
		this.promotionalFlex2 = settings.promotionalFlex2 || null;
		this.query = query;
	}
}
