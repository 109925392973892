import { TabbedShoppingTabList, ITabbedShoppingTab } from '~/components/tabbed-shopping/TabbedShopping.type';
import { s7ImagePath } from '~/global/global.constants';

export const tabbedShoppingHeadline = 'What We Love Right Now';

export const imageHeight = 363;

export const imageWidth = 389;

const trLinkEventCompType = 'tabbed shopping';

export const newArrivalsTab: ITabbedShoppingTab = {
	trLinkEventCompType,
	links: [
		{
			caption: 'New Beds',
			href: '/catalog/bedroom/beds?sort=NEWEST',
			imageHeight,
			imageSrc: `${s7ImagePath}/newArrivals_spring24_01`,
			imageWidth,
		},
		{
			caption: 'New Dressers',
			href: '/catalog/bedroom/dressers?sort=NEWEST',
			imageHeight,
			imageSrc: `${s7ImagePath}/newArrivals_spring24_02`,
			imageWidth,
		},
		{
			caption: 'New Wall Art',
			href: '/catalog/home-decor/new-wall-art',
			imageHeight,
			imageSrc: `${s7ImagePath}/newArrivals_spring24_03`,
			imageWidth,
		},
		{
			caption: 'New in Bath',
			href: '/catalog/bath/new-in-bath',
			imageHeight,
			imageSrc: `${s7ImagePath}/newArrivals_spring24_04`,
			imageWidth,
		},
	],
	title: 'New Arrivals',
	id: 'newArrivals',
};

export const easyUpdatesTab: ITabbedShoppingTab = {
	trLinkEventCompType,
	links: [
		{
			caption: 'Seasonal Favorites',
			href: '/catalog/home-decor/seasonal-favorites',
			imageHeight,
			imageSrc: `${s7ImagePath}/easyUpdates_spring24_01`,
			imageWidth,
		},

		{
			caption: 'Seasonal Living',
			href: '/catalog/living/seasonal-living',
			imageHeight,
			imageSrc: `${s7ImagePath}/easyUpdates_spring24_02`,
			imageWidth,
		},
		{
			caption: 'Seasonal Bedroom',
			href: '/catalog/bedroom/seasonal-bedroom',
			imageHeight,
			imageSrc: `${s7ImagePath}/easyUpdates_spring24_03`,
			imageWidth,
		},
		{
			caption: 'Seasonal Outdoor',
			href: '/catalog/outdoor/seasonal-outdoor',
			imageHeight,
			imageSrc: `${s7ImagePath}/easyUpdates_spring24_04`,
			imageWidth,
		},
	],
	title: 'Easy Updates',
	id: 'easyUpdates',
};

export const outdoorTab: ITabbedShoppingTab = {
	trLinkEventCompType,
	links: [
		{
			caption: 'Outdoor Sectionals',
			href: '/catalog/outdoor/sectionals',
			imageHeight,
			imageSrc: `${s7ImagePath}/outdoor_spring24_01`,
			imageWidth,
		},
		{
			caption: 'Outdoor Dining Combos',
			href: '/catalog/outdoor/favorite-outdoor-dining-combos',
			imageHeight,
			imageSrc: `${s7ImagePath}/outdoor_spring24_02`,
			imageWidth,
		},
		{
			caption: 'Outdoor Chairs',
			href: '/catalog/outdoor/chairs',
			imageHeight,
			imageSrc: `${s7ImagePath}/outdoor_spring24_03`,
			imageWidth,
		},
		{
			caption: 'Outdoor Accent Tables',
			href: '/catalog/outdoor/all-outdoor-accent-tables',
			imageHeight,
			imageSrc: `${s7ImagePath}/outdoor_spring24_04`,
			imageWidth,
		},
	],
	title: 'Outdoor',
	id: 'outdoor',
};

export const livingTab: ITabbedShoppingTab = {
	trLinkEventCompType,
	links: [
		{
			caption: 'Sofas & Sectionals',
			href: '/catalog/living/sofas-and-sectionals',
			imageHeight,
			imageSrc: `${s7ImagePath}/living_summer24_01`,
			imageWidth,
		},
		{
			caption: 'Coffee Tables',
			href: '/catalog/living/coffee-tables',
			imageHeight,
			imageSrc: `${s7ImagePath}/living_summer24_02`,
			imageWidth,
		},
		{
			caption: 'Chairs',
			href: '/catalog/living/chairs',
			imageHeight,
			imageSrc: `${s7ImagePath}/living_summer24_03`,
			imageWidth,
		},
		{
			caption: 'Media Cabinets',
			href: '/catalog/living/media-cabinets',
			imageHeight,
			imageSrc: `${s7ImagePath}/living_summer24_04`,
			imageWidth,
		},
	],
	title: 'Living',
	id: 'living',
};

export const diningTab: ITabbedShoppingTab = {
	trLinkEventCompType,
	links: [
		{
			caption: 'Dining Tables',
			href: '/catalog/dining-and-kitchen/tables',
			imageHeight,
			imageSrc: `${s7ImagePath}/dining_summer24_01`,
			imageWidth,
		},
		{
			caption: 'Dining Chairs',
			href: '/catalog/dining-and-kitchen/chairs',
			imageHeight,
			imageSrc: `${s7ImagePath}/dining_summer24_02`,
			imageWidth,
		},
		{
			caption: 'Dining Storage',
			href: '/catalog/dining-and-kitchen/storage-cabinets',
			imageHeight,
			imageSrc: `${s7ImagePath}/dining_summer24_03`,
			imageWidth,
		},
		{
			caption: 'Kitchen Islands',
			href: '/catalog/dining-and-kitchen/kitchen-islands',
			imageHeight,
			imageSrc: `${s7ImagePath}/dining_summer24_04`,
			imageWidth,
		},
	],
	title: 'Dining',
	id: 'dining',
};

export const seasonalFavesTab: ITabbedShoppingTab = {
	trLinkEventCompType,
	links: [
		{
			caption: 'Seasonal Living',
			href: '/catalog/living/seasonal-living',
			imageHeight,
			imageSrc: `${s7ImagePath}/seasonalFaves_01`,
			imageWidth,
		},
		{
			caption: 'Seasonal Dining',
			href: '/catalog/dining-and-kitchen/seasonal-dining',
			imageHeight,
			imageSrc: `${s7ImagePath}/seasonalFaves_02`,
			imageWidth,
		},
		{
			caption: 'Seasonal Bedroom',
			href: '/catalog/bedroom/seasonal-bedroom',
			imageHeight,
			imageSrc: `${s7ImagePath}/seasonalFaves_03`,
			imageWidth,
		},
		{
			caption: 'Seasonal Decor',
			href: '/catalog/home-decor/seasonal-decor',
			imageHeight,
			imageSrc: `${s7ImagePath}/seasonalFaves_04`,
			imageWidth,
		},
	],
	title: 'Seasonal Favorites',
	id: 'seasonal',
};

export const newArrivalsEasyUpdatesList: TabbedShoppingTabList = [newArrivalsTab, easyUpdatesTab];
export const outdoorEasyUpdatesList: TabbedShoppingTabList = [outdoorTab, easyUpdatesTab];
export const livingOutdoorList: TabbedShoppingTabList = [livingTab, outdoorTab];
export const diningLivingList: TabbedShoppingTabList = [diningTab, livingTab];
export const seasonalFavesDiningList: TabbedShoppingTabList = [seasonalFavesTab, diningTab];
