import React from 'react';

import { FDSModalWrapper } from '~/components/magic-modal/Components/FDSModal';
import { SwatchOrderingModalWrapper } from '~/components/magic-modal/Components/SwatchOrderingModal';
import { EmailSignUpModalNew } from '~/email-signup/Components/EmailSignUpModalNew';
import { EmailSignUpFormStoreFactory } from '~/email-signup/Stores/emailSignUpForm.store';
import { FreeDesignServicesModalDefault } from '~/free-design-services-modal/Components/freeDesignServicesModalDefault';
import { SwatchOrderingModalDefault } from '~/swatch-ordering/Components/SwatchOrderingModalDefault';
import { noop } from '~/util/noop';
import swatchStyles from '~/swatch-ordering/Components/SwatchOrderModalDefault.module.scss';

const getEmailSignUpModalStore = ({
	clickHandler = noop,
	isMediaQueryMd = false,
}) => {
	return EmailSignUpFormStoreFactory.create({
		emailSignUpFormSuccessCallback: clickHandler,
		hideLegend: true,
		locationId: 'DEFAULT',
		magicSpinnerProps: {
			isLoading: true,
		},
		source: !isMediaQueryMd ? 'PROACTIVE_MOBILE' : 'PROACTIVE_DESKTOP',
		useInlineSubmitButton: !isMediaQueryMd,
	});
};

export const personalzationModalSettings = {
	FREE_DESIGN_SERVICES: ({
		modalProps = {}
	}) => {
		return {
			WrapperComponent: FDSModalWrapper,
			flushSides: true,
			content: {
				children: <FreeDesignServicesModalDefault {...modalProps} />,
			},
			title: false,
			width: '90vw',
		};
	},
	FIRST_TO_KNOW: ({
		isMediaQueryMd = false,
		modalProps = {},
	}) => {
		//@ts-ignore
		const { clickHandler } = modalProps;

		const emailSignUpFormStore = getEmailSignUpModalStore({
			clickHandler,
			isMediaQueryMd,
		});

		const additionalModalProps = {
			emailSignUpFormStore,
			headingText: 'Be the first to know!',
			imageName: 'novato_email_signup_modal',
			imageWidth: 270,
			isNoImageEmailSignUpModalContent: !isMediaQueryMd,
			subheadingText: 'Subscribers are the first to hear about new products and clearance events.',
		};

		const combinedModalProps = {
			...modalProps,
			...additionalModalProps,
		};

		return {
			anchorBottom: !isMediaQueryMd,
			content: {
				children: (
					//@ts-ignore
					<EmailSignUpModalNew {...combinedModalProps} />
				),
			},
			dropShadow: !isMediaQueryMd,
			fullBleed: true,
			reverseCloseButton: !isMediaQueryMd,
			showOverlay: isMediaQueryMd,
			title: '',
			width: isMediaQueryMd ? 660 : '95vw',
		};
	},
	SWATCH_ORDERING: ({
		modalProps = {},
	}) => {
		return {
			WrapperComponent: SwatchOrderingModalWrapper,
			flushSides: true,
			modalClassName: swatchStyles.swatchOrdering,
			content: {
				children: <SwatchOrderingModalDefault {...modalProps} />,
			},
			title: false,
			width: '90vw',
			maxWidth: 640,
		};
	},
};
